@import '../../styles/mixins';

.templateForm {
  &__wrapper {
    :global(.FileUploadContainer .text-muted) {
      max-width: 100%;
      overflow: hidden;
      letter-spacing: normal;
      word-wrap: break-word;
    }

    &__edit {
      height: 100%;
      overflow-x: auto;
      display: flex;
      flex-direction: column;
    }
    &__tg {
      padding-bottom: 64px;
    }
  }
  &__fileName {
    max-width: 100%;
  }

  &__header {
    padding: 24px;
    @include phone-only {
      padding: 16px;
    }
  }

  &__body {
    padding: 0 24px 24px;
    max-height: calc(100vh - var(--header-height) - 70px - 24px);
    overflow: scroll;
    @include phone-only {
      flex-grow: 1;
      padding: 0 16px 16px;
    }
  }

  &__footer {
    padding: 0 24px 24px;
    &:empty {
      display: none;
    }
    @include phone-only {
      padding: 0 16px 16px;
    }
  }
}
