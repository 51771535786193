.page403 {
  width: 100%;
  height: 100vh;
  background: #f4f5f5;
  display: flex;
  align-items: center;
  justify-content: center;

  &_card {
    padding: 24px;
    background-color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;
  }
}
