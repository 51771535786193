.NoMoreThan3RequestsBlink {
  position: absolute;

  width: 570px;
  max-width: 100%;
  margin: 0 auto;

  bottom: 10px;
  left: 50%;

  &.slideInDown {
    display: block;
  }
  &.slideOutDown {
    display: block;
  }
}

@keyframes slideOutDown {
  from {
    transform: translate3d(-50%, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-50%, calc(100% + 10px), 0);
  }
}

.slideOutDown {
  animation-name: slideOutDown;
}

@keyframes slideInDown {
  from {
    transform: translate3d(-50%, -100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(-50%, 0, 0);
  }
}

.slideInDown {
  animation-name: slideInDown;
}
