.DoughnutChart {
  margin-bottom: 16px;
  width: 100%;
  &__chips {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }

  &__chip {
    padding: 2px 4px;
    border-radius: 2px;
    color: white;
    max-height: 20px;
    font-size: 12px;
    line-height: 16px;
  }
}
