.onEmptyGroupHide {
  .groupParams {
    input,
    textarea {
      background-color: white !important;
    }
  }
  &:has(.groupParams:empty) {
    display: none;
  }
}
