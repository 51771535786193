@import 'styles/mixins';

.telegram {
  background-color: white;
  transition: height 0.3s ease-in-out;
  overflow: auto;
}

.telegramHideButtons {
  :global(.modal-footer) {
    display: none;
  }
}

.customMobile {
  :global(.modal-body) {
    position: static;
  }
  @include phone-only {
    margin: 0 !important;

    :global(.modal-base-buttons) {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin: 0 !important;
      width: 100%;

      > * {
        margin: 0 !important;
        display: flex !important;
      }
    }
  }
}

.modalFormWrapper {
  margin: -24px -24px 0;
  label:empty {
    display: none;
  }

  @include phone-only {
    margin: -16px -16px 0;
  }
}
