@import '~@just-ai/just-ui/scss/basic/custom/functions';
@import '~@just-ai/just-ui/scss/basic/custom/variables';

$primary: $indigo-600;
$primary-100: $indigo-100;
$primary-200: $indigo-200;
$primary-300: $indigo-300;
$primary-500: $indigo-500;
$primary-600: $indigo-600;
$primary-700: $indigo-700;
$primary-800: $indigo-800;

$secondary: $grey-600;
$secondary-100: $grey-100;
$secondary-200: $grey-200;
$secondary-300: $grey-300;
$secondary-400: $grey-400;
$secondary-500: $grey-500;
$secondary-600: $grey-600;
$secondary-700: $grey-700;
$secondary-800: $grey-800;

$paragraph-margin-bottom: 0px;

$theme-colors-states--buttons: map-merge(
  $theme-colors-states--buttons,
  (
    'primary': (
      'default': $primary,
      'hover': $primary-800,
      'active': $primary-800,
      'disabled': $grey-300,
      'focus': $primary-700,
      'flat-active-bg': $primary-200,
    ),
    'secondary': (
      'default': $grey-100,
      'hover': $primary-800,
      'active': $primary-800,
      'disabled': $grey-300,
      'focus': $primary-700,
      'flat-active-bg': $primary-200,
    ),
  )
);

@function toRGB($color) {
  @return red($color), green($color), blue($color);
}

$base-zindex: 1;
$mid-zindex: 20;
$high-zindex: 100;
$modal-zindex: 1050;
$alerts-zindex: 1051;

//colors counterparts #343541 - grey-800

:root {
  --modal-zindex: #{$modal-zindex};
  --alerts-zindex: #{$alerts-zindex};
  --base-zindex: #{$base-zindex};
  --mid-zindex: #{$mid-zindex};
  --high-zindex: #{$high-zindex};

  --white-20per: rgba(white, 0.2);
  --grey-800-rgb: #{toRGB($grey-800)};

  --secondary-100: #{$secondary-100};
  --secondary-200: #{$secondary-200};
  --secondary-300: #{$secondary-300};
  --secondary-400: #{$secondary-400};
  --secondary-500: #{$secondary-500};
  --secondary-600: #{$secondary-600};
  --secondary-700: #{$secondary-700};
  --secondary-800: #{$secondary-800};
}
