@import '../../styles/mixins';

.chat {
  &__wrapper {
    background-color: white;
    &:global(.dark) {
      background-color: var(--grey-800);
    }
  }

  &__agentsList {
    max-height: 100%;
    @include lg-up {
      max-width: 1200px;
    }
  }
  &__loginWrapper {
    width: 300px;
    @include md-up {
      width: 600px;
    }
  }
  &__loginBtn {
    text-decoration: none;
    width: 320px;
  }
  &__histSpinner {
    position: relative;
    width: 100%;
    height: 30px;
  }
  &__chatContainer {
    height: calc(100vh - var(--header-height));
    height: calc(100dvh - var(--header-height));
    overflow-x: hidden;
    display: grid;
    grid-template-rows: 1fr auto;
    align-items: end;

    @include phone-only {
      border-top: 0;
    }

    &_withIframe {
      width: 360px;
      background-color: var(--white);
      grid-area: rightbar;
      grid-template: 'history' 1fr;
    }
    &_fullWidth {
      grid-column: 1 / -1;
    }
  }
  &__histWrapper {
    display: flex;
    flex-direction: column;
    margin: 8px 24px 54px;
    scroll-padding-top: var(--header-height);
    gap: 8px;

    @include phone-only {
      margin: 12px 12px 36px;
    }

    &_canvasChat {
      //height: 100%;
      grid-area: history;
      //overflow-y: auto;
    }
  }
  &__settingsModal {
    margin-top: 2.5rem;
    @include md-up {
      margin-right: auto;
      margin-left: auto;
      max-width: 36rem;
      gap: 1.5rem;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
    }
    @include lg-up {
      max-width: 42rem;
      padding-left: 0;
      padding-right: 0;
    }
    @include xl-up {
      max-width: 48rem;
    }
  }
  &__settingsBlock {
    margin-top: 1rem;
    border-bottom: 1px solid var(--grey-200);
    &:global(.dark) {
      border-color: var(--grey-600);
    }
    @include md-up {
      border-radius: 0.5rem;
      border: 1px solid var(--grey-200);
    }
  }
  &__endMsg {
    height: 250px;
    background-color: white;
    &:global(.dark) {
      background-color: var(--grey-950);
    }
  }
  &__messageWrapper {
    color: var(--grey-950);

    &__response,
    &__system {
      &:has(&__body:empty) {
        display: none;
      }
    }
    &__request {
      &:has(&__body:empty) {
        display: none;
      }
    }
  }
  &__errorBlock {
    position: relative;
    margin: -10px auto auto;
  }
  &__message {
    font-size: 14px;
    line-height: 24px;
    padding: 8px;
    gap: 8px;

    :global(.items-start) {
      align-items: flex-start;
    }
    img {
      max-width: 256px;
      max-height: 256px;
    }
    audio {
      max-width: 300px;
    }
    @include md-up {
      max-width: 42rem;
    }
    @include lg-up {
      padding-left: 0;
      padding-right: 0;
    }
    @include xl-up {
      max-width: 48rem;
    }

    &__title {
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.24px;
      margin-bottom: 4px;
      &__person {
        text-transform: uppercase;
        color: var(--gray-500);
        font-weight: 700;
      }
      &__time {
        color: var(--gray-600);
      }
    }
  }
  &__messageElementPadding {
  }

  &__messageText {
    font-size: 14px;
    line-height: 24px;
    p {
      line-height: 24px;
      white-space: pre-line;
    }
    ul {
      margin-bottom: 16px;
    }
    :global(.codeblock) {
      border-radius: 4px;
      background-color: var(--grey-950);
    }
    :global(.react-pdf__Document) {
      height: 550px;
    }
  }
  &__messageButtons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1.25rem;
    margin-top: 12px;

    @include md-up {
      gap: 0.75rem;
    }
  }
  &__tokensSpent {
    margin-left: auto;
    @include md-up {
      font-size: 12px;
      line-height: 18px;
      margin-left: 12px;
      color: var(--grey-800);
    }
  }
  &__maskedBanner {
    background: var(--grey-200);
    border-radius: 4px;
    color: var(--grey-800);
    padding: 0 5px;
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: -2px;
    cursor: pointer;
  }
  &__tooltipInner {
    min-width: 330px;

    &__entityList {
      padding-left: 1.5em;
      padding-top: 2px;
      font-size: 14px;
      margin-bottom: 0.5em;
    }
  }
  &__messageButton {
    color: $secondary-600;
    &:hover {
      color: $primary-600;
    }
  }
  &__editTxtarea {
    resize: none;
    white-space: pre-wrap;
    border: none;
    color: var(--grey-100);
    &:global(.dark) {
      background-color: var(--grey-800);
    }
  }

  &__bannerContainer {
    margin: auto;
    width: 100%;
  }

  &__newMessageBlock {
    position: sticky;
    bottom: 0;
    margin: 0 24px 0;
    background-color: var(--white);

    @include phone-only {
      margin: 0;
    }
  }
  &__newMessageBlockWrapper {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    &:global(.dark) {
      border-color: var(--white-20per);
    }
    @include md-up {
      margin-bottom: 1rem;
    }
    @include lg-up {
      margin-left: auto;
      margin-right: auto;
      max-width: 48rem;
    }

    @include phone-only {
      margin: 0;
    }
  }
  &__newMessageInputWrapper {
    padding: 12px 113px 12px 12px;
    border: 1px solid var(--gray-200);
    background-color: white;
    &:focus-within {
      border-color: var(--primary-500);
    }
    &:global(.dark) {
      border-color: var(--grey-600);
      background-color: var(--grey-700);
      color: white;
      @include sm-up {
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
      }
    }
    @include sm-up {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      border-radius: 0.5rem;
    }

    @include phone-only {
      border-top: 1px solid var(--gray-200);
      border-right: none;
      border-bottom: none;
      border-left: none;
    }
  }
  &__newMessageTextarea {
    margin: 0;
    padding: 0.5rem 1rem 0.5rem 0.5rem;
    border: none;
    background-color: transparent;
    color: black;
    overflow-x: hidden;
    &:focus {
      outline: none;
    }
    &:global(.dark) {
      color: white;
    }
  }

  &__agentModalBtn {
    width: 750px;
  }

  &__agentData {
    @include sm-up {
      max-width: 600px;
    }
    @include md-up {
      padding-top: 2.5rem;
    }
  }
  &__agentsHeader {
    margin-top: 40px;
    padding-left: 32px;
  }

  &__editInput {
    max-width: 300px;
    width: 100%;

    &:global(.dark) {
      color: white;
    }
  }
  &__inputBtnWrapper {
    position: absolute;
    display: flex;
    gap: 4px;
    align-items: center;
    right: 12px;
    bottom: -7px;
    transform: translateY(-50%);

    :global(.divider) {
      width: 1px;
      height: 30px;
      background-color: var(--grey-200);
    }
  }
  &__contextActionContainer {
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-top: 8px;
  }
  &__additionalActionContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    transform: translateY(-100%);
    width: 100%;

    top: -12px;

    @include phone-only {
      padding: 0 8px;
      top: -8px;
    }
  }
  &__inputBtnScrollDown {
    width: 44px;
    height: 44px;
    background-color: white !important;
    border-radius: 50% !important;
    align-self: flex-end;
    box-shadow:
      0px 0px 1px 0px rgba(17, 60, 92, 0.06),
      0px 1px 3px 0px rgba(12, 43, 66, 0.06),
      0px 0px 1px 0px rgba(0, 0, 0, 0.16);

    @include phone-only {
      margin-right: 8px;
    }
  }

  &__btnStop {
    align-self: flex-start;
    &:global(.btn) {
      background-color: white;
    }

    @include phone-only {
      align-self: flex-end;
    }
  }

  &__fileWrapper {
    background-color: var(--grey-100);
    padding-left: 8px;
    border-radius: 4px;
    p {
      max-width: 185px;
    }

    &_deleteButton {
      padding: 8px !important;
    }
  }

  &__fileMessage {
    display: flex;
    align-items: center;
    color: var(--grey-950);
    background-color: var(--grey-100);
    border-radius: 4px;
    padding: 8px;
    text-decoration: none;
    margin-right: auto;
    :global(.justui__icon-wrapper) {
      margin-left: 10px;
    }
  }

  &__loader {
    font-size: 14px;
    &::after {
      display: inline-block;
      animation: ellipsis steps(1, end) 1s infinite;
      content: '';
    }
  }
  &__recordingIndicator {
    width: 12px;
    height: 12px;
    background-color: var(--danger);
    border-radius: 50%;
    margin-left: 8px;
    &--paused {
      background-color: var(--grey-200);
    }
  }

  &__showUiButton {
    width: 30px;
    height: 30px;
    padding: 0 !important;
    background-color: var(--white) !important;
    color: var(--black) !important;
    border-color: transparent !important;
  }

  &_imageItem {
    overflow: hidden;
    position: relative;
    display: inline-flex;
    margin-right: 8px;
    margin-bottom: 8px;
    &:hover {
      .chat_imageItem__overlay {
        visibility: visible;
      }
    }

    &__overlay {
      position: absolute;
      visibility: hidden;
      top: 8px;
      right: 8px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-end;
      gap: 8px;
    }
  }
}

:global(.pswp__img) {
  object-fit: contain;
}

@keyframes ellipsis {
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
  100% {
    content: '';
  }
}

.chatTooltip {
  margin: 0 10px;
  padding: 4px 8px 6px;
  background-color: var(--gray-600);
  color: var(--white);
  border-radius: 3px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  letter-spacing: 0.24px;
}

.SystemAppUpdate {
  &_main {
    border-bottom: none !important;
    padding: 0 0 8px 0 !important;
    background-color: transparent !important;
  }
  &_header {
    font-weight: normal !important;
    color: var(--gray-600);
    font-size: 14px;
    line-height: 21px;
    gap: 12px;
  }
  &_icon {
    transform: rotate(0) !important;
    &:global(.opened) {
      transform: rotate(90deg) !important;
    }
  }
}

.DayMessage {
  color: var(--gray-600, #6a7178);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.28px;
}
