@import './styles/index.scss';
@import '~@just-ai/just-ui/scss/basic/jaicp/colors';
@import '~@just-ai/just-ui/scss/basic/build.main';
@import './styles/pseudoTailwind.scss';
@import './styles/mixins';

html {
  background-color: var(--white) !important;
}

body {
  overflow: hidden;
  color: var(--gray-800);
}

.justui__icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.justui_button.btn {
  display: flex;
}

.bordered-panel-white {
  border: 1px solid rgba($color: white, $alpha: 0.2);
  &--rounded {
    border-radius: 0.375rem;
  }
}

.dark {
  label {
    color: white;
  }
}

.react-joyride {
  z-index: 1090;
  .react-joyride__step {
    z-index: 1091;
  }
}
.react-joyride__step {
  h4 {
    padding: 0 32px 0 16px;
    margin-bottom: 1rem !important;
  }
}

.hidden {
  display: none;
}

.mobileBottomModal {
  @include phone-only {
    top: 100vh !important;
    top: 100dvh !important;
    margin: 0 !important;
    transform: translateY(-100%) !important;

    .modal-base-buttons {
      display: flex;
      flex-direction: column;
      margin: 0 !important;
      flex-grow: 1;
      gap: 8px;
      > * {
        display: flex;
        width: 100%;
        margin: 0 !important;
        flex-grow: 1;
      }
    }
  }
}

.form-control,
.textAreaLikeElement,
.just-select-container input.single,
.phone-wrapper .phone-input {
  &:not(.greyParent) {
    background-color: var(--grey-100);
  }
}

.input-group.input-group-lg .IconButtons-wrap .btn-flat {
  height: 100%;
  margin-top: 0;
}

.notification-container {
  z-index: $alerts-zindex;
  @include phone-only {
    top: 100vh;
    top: 100dvh;
    transform: translateY(-100%);
    padding: 0;
    margin-top: -90px;
    width: 100dvw;

    .notification {
      max-width: 100dvw;
    }
  }
}

.overflow-scroll-y {
  overflow-y: scroll;
}

.animated {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.requestTemplateModalInfo {
  display: flex;
  flex-direction: column;
  gap: 8px;
  white-space: pre-line;
  padding: 12px;
  border-radius: 4px;
  background-color: var(--indigo-100);
}

a {
  text-decoration: none;
}

.tableWithBorderedRows {
  text-align: end;
  border-collapse: separate;
  border-spacing: 0;
  tbody {
    td {
      border: solid 2px var(--grey-200);
      border-style: solid none;
      padding: 16px 0;
    }

    td:first-child,
    th:first-child {
      border-left-style: solid;
      border-radius: 8px 0 0 8px;
    }

    // Set border-radius on the top-right and bottom-right of the last table data on the table row
    td:last-child,
    th:last-child {
      border-right-style: solid;
      border-radius: 0 8px 8px 0;
    }
  }
}
