.urlField {
  padding: 8px;
  width: 100%;
  border-radius: 3px;
  background-color: var(--blue-100);
  text-decoration: none;
  color: var(--grey-800);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    color: var(--grey-800);
  }
}
