@import '../../styles/mixins';

.Pagination {
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
  align-items: center;
  align-self: flex-start;

  &_paginator {
    margin-bottom: 0;
    padding: 0;
    display: flex;
    align-items: center;

    & > * {
      display: flex;
    }

    .Pagination_page,
    .Pagination_previous,
    .Pagination_next {
      height: 20px;
      min-width: 23px;
      border: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 23px;

      &:active {
        outline: none;
      }

      &.disabled {
        cursor: not-allowed;
        path {
          fill: #ccc;
        }
      }

      &_link {
        width: 100%;
        height: 100%;
        padding: 0 8px;
        text-align: center;
        display: block;
        line-height: 21px;
      }

      &.Pagination_active {
        background-color: var(--gray-100);
        border-radius: 1px;
      }
      @include phone-only {
        height: 32px;
        min-width: 32px;
        &_link {
          line-height: 32px;
        }
        svg {
          height: 24px;
          width: 24px;
        }
      }
    }
  }
}
