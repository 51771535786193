.customToolAssistantActionsDropdown {
  &_item {
    border-bottom: 1px solid var(--grey-300);
    animation-delay: var(--animate-delay);

    &:last-child {
      border-bottom: none;
    }
  }
}
