@import '../../../styles/mixins';

.header {
  grid-area: content-header;
  background-color: white;
  align-items: flex-start;
  padding: 16px 24px;

  @include phone-only {
    gap: 16px;
    padding: 16px;
  }

  &:global(.dark) {
    border: none;
    background-color: var(--grey-700);
    color: var(--grey-200);
  }

  &__icon {
    width: 30px;
    height: 30px;
    color: var(--gray-800) !important;

    svg {
      width: 12px !important;
      height: 12px !important;
    }

    &.disabled,
    &:disabled {
      color: var(--gray-400) !important;
    }

    @include phone-only {
      width: 24px;
      height: 24px;
      svg {
        width: 24px !important;
        height: 24px !important;
      }
    }

    padding: 0 !important;

    &_active {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        background-color: var(--danger);
        border-radius: 50%;
        left: 16px;
        top: -5px;
        border: 2px solid var(--white);
      }
    }
  }

  &__reset {
    font-size: 12px;
    @include phone-only {
      padding: 0 !important;
    }
  }

  &__name {
    b {
      font-size: 16px;
      line-height: 24px;
    }

    @include phone-only {
      margin-right: 0;
      flex-grow: 1;
    }
  }
  &__contextBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    padding: 0 8px;
    border: 1px solid var(--gray-300);
    border-right: none;
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
    font-size: 12px;
    line-height: 18px;
    b {
      margin-left: 4px;
    }
  }
  &__qIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: var(--gray-200);
    margin-left: 8px;
    @include phone-only {
      width: 24px;
      height: 24px;
    }
  }
  &__popover {
    width: 97%;
    max-width: 100vw !important;
    border-radius: 0 !important;
    box-shadow:
      0px 0px 1px 0px #113c5c0f,
      0px 14px 28px 0px #0c2b421f,
      0px 0px 2px 0px #0000001f !important;
    border: none !important;
    padding: 12px;
    &__context {
      padding: 16px;
      background-color: var(--grey-100);
      p {
        line-height: 1.5rem;
      }
    }
  }

  &__contextTooltip {
    &:global(.tooltip-inner) {
      max-width: 400px;
    }
  }

  &__dropdown {
    box-shadow:
      0 0 1px 0 #113c5c0f,
      0 6px 12px 0 #0c2b4229,
      0 0 2px 0 #0000001a;
  }
}
