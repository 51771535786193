@import 'styles/mixins';

.agentSettings {
  &__wrapper {
    width: 100%;
    grid-area: rightbar;
    background-color: white;
    overflow: hidden;
    max-height: 100vh;
    max-height: 100dvh;
    border-left: 1px solid var(--gray-200);

    @include phone-only {
      display: none;
      border-left: none;
      &:global(.visible) {
        max-height: calc(100vh - var(--header-height));
        max-height: calc(100dvh - var(--header-height));
        display: block;
        z-index: 10;
        padding-top: 0;
        grid-area: main;
        width: 100%;
      }
      &--offset {
        &:global(.visible) {
          max-height: calc(100vh - 120px);
          max-height: calc(100dvh - 120px);
        }
      }
    }
    :global(.react-pdf__Document) {
      overflow-y: auto;
      overflow-x: hidden;
      height: calc(100vh - var(--header-height));
    }
  }
  &_main {
    grid-area: main;
  }

  &_fileViewer {
    width: 32vw;
  }

  &__modal {
    :global(.modal-body) {
      padding: 0 !important;
    }
    :global(.modal-body-container) {
      padding: 0 !important;
    }
    :global(.modal-footer) {
      padding-top: 0 !important;
    }
  }
}
