.UserExpenses {
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 24px;

    svg {
      cursor: pointer;
    }
  }

  &__content {
    padding: 0 24px 28px 24px;
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
  }

  &__userdata {
    display: flex;
    flex-direction: column;
    gap: 4px;

    h3:last-child {
      color: var(--gray-600);
    }

    margin-bottom: 16px;
  }
}
