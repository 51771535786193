.canvasHolder {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 48px 1fr;
  padding-top: 16px;
  grid-area: main;
  overflow: hidden;

  &_Tabs {
    display: flex;
    align-items: baseline;
    overflow-x: auto;
    overflow-y: hidden;

    &__tab {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      height: 100%;
      background-color: #ffffff;
      border-bottom: 0;
      border-radius: 8px 8px 0 0;
      padding: 12px;
      cursor: pointer;
      font-size: 14px;
      line-height: 24px;
      background: var(--gray-50);
      color: var(--gray-600);
      overflow: hidden;

      &__name {
        max-width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &__activeTab {
        background: var(--white);
        color: var(--gray-950);
      }
    }
  }

  &_iframe {
    border-radius: 0;
    overflow: hidden;
    border: 0;
    background-color: white;
  }
}
//.canvasHolder_Tabs {
//  grid-area: tabs;
//}
//.iframe {
//  grid-area: iframe;
//}
