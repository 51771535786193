.select {
  &:global(.just-select-wrapper) {
    width: 100%;
    margin-bottom: 24px;
  }
  :global(.just-select-wrapper_container) {
    min-height: 82px;
  }
  :global(.just-select-selected-item) {
    background-color: var(--grey-200);
    border-color: var(--grey-200);
    color: var(--grey-800);
  }
}

.badge {
  display: flex;
  gap: 4px;
  align-items: center;
  background-color: var(--gray-200);
  border-radius: 2px;
  padding: 2px 4px;
  font-size: 12px;
  line-height: 16px;
}
