.emojiPickerBtn {
  width: 56px;
  height: 56px;
  text-align: center;
  border-radius: 100% !important;
  border: 1px solid var(--grey-300) !important;
  padding: 0 !important;
  &--empty {
    border-style: dashed !important;
  }
}

.emojiPickerMenu {
  z-index: -1 !important;
  transform: translate3d(-115px, 56px, 0px) !important;
  &:global(.visible) {
    z-index: 1051 !important;
  }
}

.pickedEmoji {
  font-size: 32px;
}
