.AnalyticsDatePicker {
  padding: 5px 8px;
  border-radius: 4px;
  border: 1px solid var(--primary-200);
  background-color: var(--primary-100);
  position: relative;

  &__text {
    font-size: 12px;
  }
  &__dates {
    color: var(--indigo-600);
  }

  &__datepicker {
    position: absolute;
  }

  &__calendar {
    top: 0 !important;
    z-index: 6;
  }
}
