.bannerContainer {
  color: var(--gray-800);
  padding: 5px;
  margin-right: -8px;

  &__icon {
    color: var(--red-600);
    margin-right: 7px;
    transform: translateY(-1px);
  }

  &__entityList {
    padding-left: 1.5em;
    padding-top: 2px;
  }
}
