@import '../../styles/_mixins.scss';

.accountPage {
  &__block {
    min-width: 320px;
    background-color: var(--grey-100);
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    &--maxWidth {
      max-width: 320px;
    }
    a {
      text-decoration: none;
    }
    @include phone-only {
      width: 100%;
    }
    &__text {
      color: var(--secondary);
      margin-bottom: 10px;
    }
    &__tooltip {
      margin: -8px -8px 0 0;
    }
    &__tooltipInner {
      min-width: 300px;

      &__link {
        color: var(--indigo-200);
        text-decoration: none;

        &:hover {
          color: var(--indigo-200);
          text-decoration: underline;
        }
      }
    }

    &__info {
      width: 100%;
      display: flex;
      font-size: 32px;
      font-weight: 500;
      margin: 4px 0 10px 0;
      align-items: baseline;
      gap: 8px;

      &__frac {
        font-size: 14px;
        font-weight: 400;
        padding-left: 4px;
      }
    }
    &__keys {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    &__company {
      font-size: 24px;
      line-height: 24px;
      font-weight: 500;
    }
    &__data {
      color: var(--gray-950);
    }
  }
  &__footer {
    margin-top: auto;
    padding-top: 30px;
    padding-bottom: 10px;
    a {
      text-decoration: none;
    }
  }
  &__badge {
    padding: 2px 4px;
    background-color: var(--grey-600);
    color: white;
    border-radius: 2px;
    margin-left: 8px;
  }
  &__modal {
    &__copyWrapper {
      padding: 8px 16px;
      margin-bottom: 16px;
      background-color: var(--grey-100);
      border: 2px solid var(--grey-300);
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      b {
        max-width: 90%;
        line-height: 21px;
      }
    }
    &__jStatus {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 13px 8px;
      border-radius: 3px;
      margin-bottom: 24px;
      &--success {
        background-color: var(--green-100);
      }
      &--error {
        background-color: var(--red-100);
      }
    }
    &__info {
      display: flex;
      align-items: baseline;
      gap: 8px;
    }
    &__settings {
      margin-top: 24px;
    }
  }
  &__promocodeSuccess {
    display: flex;
    align-items: baseline;
    padding: 12px 8px;
    background-color: var(--green-200);
    max-width: 288px;
    border-radius: 3px;
    margin-top: 8px;
  }
}
