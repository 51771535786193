.table {
  width: 100%;

  & > thead > tr,
  & > tbody > tr {
    border-bottom: 1px solid var(--gray-200);
  }

  & > tbody > tr {
    &:hover {
      background-color: var(--gray-100);
    }
  }

  .th {
    font-weight: 700;
    font-size: 12px;
    color: var(--gray-500);
    padding: 0 8px 16px 8px;
  }

  & > tbody > tr > td {
    padding: 8px;
    color: var(--gray-950);
    vertical-align: middle;
  }
}
.partialSelect {
  :global(.custom-control-label) {
    &:after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' viewBox='0 0 341.333 341.333'%3e%3cg%3e%3cg%3e%3crect y='149.333' width='341.333' height='42.667'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e") !important;
    }
  }
}

.tr {
  height: 46px;
}
