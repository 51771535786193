@import '../../../../../../styles/mixins';

.FileField {
  &__fileName {
    max-width: 240px;
    @include md-up {
      max-width: 420px;
    }
  }
}
