@import 'styles/mixins';

.corporatePage {
  max-width: 976px;
  &__llmsList {
    background-color: var(--blue-100);
    max-width: 600px;
    border-radius: 4px;
  }
  &__tabContent {
    background-color: var(--grey-100);
    border-radius: 4px;
    @include phone-only {
      border-bottom: 1px solid var(--grey-200);
      border-radius: 0;
      &:first-of-type {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
      &:last-of-type {
        border-bottom: none;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
  #shieldGradient {
    --color-stop: #fe6da4;
    --color-bot: #ad8be3;
  }
  &__btnGradient {
    fill: url(#shieldGradient) #fff;
  }
  &__solutionBlock {
    padding: 16px;
    border: 1px solid var(--grey-200);
    border-radius: 4px;
    color: var(--grey-950);
  }
}
