.FileUpload {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__label {
    color: var(--gray-800);
    font-size: 14px;
    line-height: 20px;
  }
  &__bottom_description {
    font-size: 12px;
    line-height: 16px;
    & .description {
      color: var(--gray-600);
    }
    & .error {
      color: var(--red-600);
    }
  }

  &__field {
    background-color: var(--white);
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    border: 1px dashed var(--form-border-default-gray-300, #c3c6c9);

    .input {
      display: block;
      opacity: 0;
      width: 0;
      height: 0;
      overflow: hidden;
      border: none;
    }

    &:focus-within {
      outline: none;
      border: 1px solid var(--indigo-600);
      background-color: var(--grey-100);
    }
    &:hover {
      background-color: var(--grey-100);
    }

    &.size_lg {
      padding: 32px;
    }
    &.size_md {
      padding: 16px;
    }
    &.size_sm {
      padding: 8px 12px;

      & .emptyBox {
        justify-content: flex-start;
        & .readyState {
          text-align: start;
        }
      }
    }

    &_dragging {
      background-color: var(--indigo-100);
      border: 1px solid var(--indigo-600);
    }
    .showWhenDragging {
      display: none;
    }
    &_dragging .hideWhenDragging {
      display: none;
    }
    &_dragging .showWhenDragging {
      display: block;
      color: var(--indigo-600);
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.105px;
    }
    &_dragging_disabled {
      color: var(--grey-300) !important;
    }

    &_invalid {
      border: 1px dashed var(--danger);
    }
    &_loading {
      pointer-events: none;
    }

    &__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      gap: 24px;

      & .titleBox {
        display: flex;
        align-items: center;
        gap: 8px;
        flex: 1;
        min-width: 0;

        .title {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          flex: 1;
          min-width: 0;

          &__name {
            color: var(--gray-950);
            font-size: 14px;
            font-weight: 700;
            line-height: 21px;
            letter-spacing: 0.28px;
            width: 100%;
            text-overflow: ellipsis;
            white-space: normal;
            overflow: hidden;
          }
        }
      }
      & .actions {
        display: flex;
        align-items: center;

        & svg {
          color: var(--gray-600) !important;
        }
      }
    }

    .emptyBox {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      cursor: pointer;

      & .readyState {
        text-align: center;

        & > span {
          white-space: nowrap;
        }
      }
    }

    & :global(.UploadArea__fakeLink) {
      color: var(--indigo-600);
      border-bottom: 1px dashed var(--indigo-600);
    }
  }
}
