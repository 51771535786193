@import '../../../styles/mixins';

.tabs {
  margin: 16px 0 16px 0;
  flex-wrap: nowrap !important;
  overflow-x: scroll;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  & :global(.nav-link) {
    white-space: nowrap !important;
  }
}

.Limits {
  &__wrapper {
    max-width: 900px;
    min-width: 760px;
    width: 100%;
  }
  &__searchInput {
    max-width: 280px;
  }
  &__addBtn {
    min-width: 140px;
  }
}

.apiTab {
  &__empty {
    min-height: 250px;
    :global(.fa-ghost) {
      color: var(--grey-400) !important;
    }
  }
}
