@import 'styles/mixins';

.chatbarSettings {
  &__wrapper {
    font-size: 14px;
    align-items: flex-start;

    &:global(.dark) {
      border-top: 1px solid var(--white-20per);
    }
    padding: 8px 12px;
    @include phone-only {
      padding: 4px 16px 16px;
    }
  }
  &__btnFancy {
    color: white !important;
    span {
      color: white !important;
    }
    svg {
      color: white !important;
    }
    &:hover {
      color: white !important;
    }
    background: linear-gradient(90deg, #98a2ff, #be5ad7);
  }
  &__modalLink {
    text-decoration: none;
    padding: 8px 12px;
    gap: 8px;
  }
  &__modal {
    :global(.modal-body) {
      padding-left: 16px !important;
    }
    :global(.modal-body-container) {
      padding: 0 !important;
    }
  }
  &__dropdown {
    :global(.dropdown-item) {
      padding: 8px 4px;
      p {
        min-width: 200px;
        margin-top: 2px;
      }
    }
  }
  &__commIcon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
}

.selected {
  border-radius: 4px;
  background-color: var(--grey-200);
  &:global(.dark) {
    background-color: var(--grey-800);
  }
  &--main {
    border-color: var(--primary);
    background-color: var(--primary-200);
  }
}

.navLink {
  max-width: 100%;
  white-space: nowrap;
  display: flex;
  min-height: 38px;
  margin-bottom: 8px;
  padding-right: 8px;
  &--main {
    border: 2px solid var(--grey-200);
    border-radius: 4px;
    padding: 0 8px;
    color: var(--primary);
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 0;
  }

  @include phone-only {
    min-height: 48px;
  }
  &:hover {
    background-color: var(--gray-200) !important;
    border-radius: 4px;
    &:active {
      background-color: var(--gray-300) !important;
    }
  }
  &:focus {
    box-shadow: 0 0 0 1px var(--primary);
  }

  &__icon {
    visibility: hidden;
    @include phone-only {
      visibility: initial;
    }
  }
  &:hover &__icon, &__icon_visible{
    visibility: initial;
  }
}

.editWrapper {
  padding: 11px 9px;
}

.editInput {
  border: none;
  outline: none;
  font-size: 12px;
  background-color: transparent;
  margin-right: 3.5rem;
  &:global(.dark) {
    color: white;
  }
}

.tip {
  background-color: var(--primary-600);
  width: 8px;
  height: 8px;
  border-radius: 50%;

  &_absolute {
    position: absolute;
    top: -2px;
    right: -2px;
  }
}
