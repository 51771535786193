.filterContainer {
  padding: 5px 8px;
  border-radius: 4px;
  border: 1px solid var(--primary-200);
  background-color: var(--primary-100);
  position: relative;

  &__accentText {
    color: var(--indigo-600);
  }

  &__dropdown {
    width: 300px;
    padding: 0;
    background-color: var(--white);
    box-shadow:
      0px 0px 1px 0px #113c5c0f,
      0px 6px 12px 0px #0c2b4229,
      0px 0px 2px 0px #0000001a;

    :global(.custom-checkbox.custom-control) {
      padding-left: 14px;
      line-height: 14px;
    }
    :global(.custom-control-input) {
      height: 14px;
    }
    :global(.custom-control-label) {
      &:before,
      &:after {
        top: 0;
        left: -14px;
      }
    }
  }
}
.searchInputSize {
  :global(.form-control) {
    padding-top: 1px;
    padding-bottom: 1px;
    height: 32px;
  }
}
.partialSelect {
  :global(.custom-control-label) {
    &:after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' viewBox='0 0 341.333 341.333'%3e%3cg%3e%3cg%3e%3crect y='149.333' width='341.333' height='42.667'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e") !important;
    }
  }
}
