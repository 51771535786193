.packages {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 24px 0;
  b {
    font-size: 16px;
  }
  &__package {
    cursor: pointer;
    position: relative;
    padding: 16px;
    border: 2px solid var(--grey-200);
    border-radius: 8px;
    &__hl {
      border-color: var(--primary);
    }
    ul {
      padding-left: 24px;
    }
  }
  &__checkbox {
    position: absolute !important;
    top: 8px;
    right: 8px;
    :global(.custom-control-label::before) {
      border-radius: 100% !important;
    }
  }
  &__bottomLink {
    display: flex;
    gap: 8px;
    align-items: center;
    text-decoration: none;
  }
}
