.Drawer {
  $p: &;

  &.open &__content {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  }

  &__backdrop {
    visibility: hidden;
    opacity: 0;
    background: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s ease, visibility 0.3s ease;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    pointer-events: none;
    z-index: 0;
  }

  &.in.open &__backdrop {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    z-index: 999;
  }

  &__content {
    background: #fff;
    max-width: 603px;
    width: 100%;
    min-width: 360px;
    height: 100%;
    overflow: auto;
    position: fixed;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease;
    z-index: 1000;

    &.right {
      top: 0;
      right: 0;
      transform: translateX(100%);

      #{$p}.in.open & {
        transform: translateX(0);
      }
    }
  }
}
