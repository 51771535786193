@import './variables';

@mixin xs-up {
  @media (min-width: map-get($grid-breakpoints, 'xs')) {
    @content;
  }
}

@mixin sm-up {
  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    @content;
  }
}

@mixin md-up {
  @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
    @content;
  }
}

@mixin lg-up {
  @media (min-width: map-get($grid-breakpoints, 'lg')) {
    @content;
  }
}

@mixin xl-up {
  @media (min-width: map-get($grid-breakpoints, 'xl')) {
    @content;
  }
}

@mixin phone-only {
  @media (max-width: map-get($grid-breakpoints, 'sm')) {
    @content;
  }
}

@mixin notebook-only {
  @media (max-width: map-get($grid-breakpoints, 'lg')) {
    @content;
  }
}

@mixin scrollbar() {
  &::-webkit-scrollbar {
    min-width: 6px;
    width: 6px;
    padding-right: 2px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    min-height: 60px;
    height: 60px;
    border-radius: 100px;
    background-color: var(--palette-primary-30);
    opacity: 0.5;
    border: none;
  }

  &::-webkit-scrollbar-button {
    display: block;
    height: 0;
  }
}

@mixin dropdown() {
  .dropdownToggle {
    display: flex;
    column-gap: 4px;
    white-space: nowrap;
    font-size: 12px;
    line-height: 20px;
    border-radius: 4px;
    padding-right: 1px;
    cursor: pointer;

    &:hover {
      background-color: #e0e1e3;
    }

    &__Item {
      display: inline-flex;
    }
  }

  .dropdownMenu {
    box-shadow:
      0px 0px 1px 0px #113c5c0f,
      0px 6px 12px 0px #0c2b4229,
      0px 0px 2px 0px #0000001a;

    :global(.dropdown-divider) {
      margin: 0;
    }

    &__Item {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 8px 12px;
      line-height: 21px;
      font-size: 14px;
      user-select: none;
      color: var(--gray-800);
      gap: 8px;

      &:hover {
        background-color: var(--gray-200);
      }

      &__danger {
        color: var(--red-600);

        &:hover {
          background-color: var(--red-200);
        }
      }

      &__muted {
        font-size: 12px;
        line-height: 18px;
        color: var(--gray-600);

        &:hover {
          background-color: transparent;
        }
      }

      &__iconPlaceholder {
        width: 16px;
      }
    }
  }
}
