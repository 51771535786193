@import '../../../../styles/mixins';

.Table {
  width: 100%;
  margin-bottom: 16px;

  &__empty {
    padding: 48px 0 48px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__search {
    margin-bottom: 16px;
    input {
      background-color: #fff;
    }
    button {
      height: 100%;
    }
  }

  td {
    padding: 12px;
  }

  &__heading {
    background-color: var(--gray-100);
    color: var(--gray-600);
    font-weight: 700;
  }

  &__data {
    border-bottom: 1px solid var(--gray-200);
  }

  &__link {
    cursor: pointer;
    &:hover {
      color: var(--primary);
    }
  }

  &__fitWidth {
    width: 1px;
    white-space: nowrap;
    text-align: right;
  }

  &__secondary {
    color: var(--gray-600);
  }

  &__limits {
    thead {
      td {
        color: var(--secondary-500);
        font-weight: bold;
        padding-left: 0;
        padding-right: 0;
      }
    }
    tbody {
      tr:nth-of-type(odd) {
        td {
          height: 76px;
        }
      }
    }
  }
  &__offsetCell {
    //отрицательный маргин чтобы выравнять абсолютно спозиционированную галочку в чекбоксах, которая реагирует на ширину столбцов
    margin: 0 -16px 0 16px;
  }
  &__dropdownItem {
    &:global(.dropdown-item) {
      padding: 8px 12px;
      gap: 8px;
    }
    &--red {
      &:global(.dropdown-item) {
        color: var(--red);
      }
    }
  }
}

.text-left {
  text-align: start;
}

.text-center {
  text-align: center;
}
