.dropdownMenu {
  box-shadow:
    0px 0px 1px 0px #113c5c0f,
    0px 6px 12px 0px #0c2b4229,
    0px 0px 2px 0px #0000001a;

  :global(.dropdown-divider) {
    margin: 0;
  }

  &__Item {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    line-height: 21px;
    font-size: 14px;
    user-select: none;
    color: var(--gray-800);
    gap: 8px;

    &:hover {
      background-color: var(--gray-200);
    }

    &__danger {
      color: var(--red-600);

      &:hover {
        background-color: var(--red-200);
      }
    }

    &__muted {
      font-size: 12px;
      line-height: 18px;
      color: var(--gray-600);

      &:hover {
        background-color: transparent;
      }
    }

    &__iconPlaceholder {
      width: 16px;
    }
  }
}

.tableFixHead {
  overflow: auto;
  max-height: 100%;
  width: 100%;
  height: 0;
  thead th {
    position: sticky;
    top: 0;
    z-index: 1;
    border-bottom: 1px solid var(--gray-200);
    background-color: var(--white);
  }
}

.searchInputSize {
  :global(.form-control) {
    padding-top: 1px;
    padding-bottom: 1px;
    height: 32px;
  }
}

.file_button {
  width: 30px;
  height: 30px;
}

.mimeContainer {
  display: flex;
  width: 32px;
  height: 32px;
  background-color: var(--gray-100);
  border-radius: 4px;
  align-items: center;
  justify-content: center;

  .favicon {
    width: 20px;
  }
  &_icon {
    width: 20px !important;
    height: 20px !important;
  }
}

.mimeImageContainer {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  overflow: hidden;
  img {
    max-width: 100%;
  }
}
