@import 'styles/mixins';

.topPanel {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 8px;
  min-height: 46px;
  a {
    text-decoration: none;
  }
  &_payment {
    p {
      margin: 0 auto;
    }
    background-color: var(--yellow-100);
  }
  &_demo {
    background-color: var(--primary-100);
    justify-content: center;
    gap: 12px;
  }
  &_done {
    background-color: var(--green-200);
  }
  &_failed {
    background-color: var(--red-200);
  }
  &_jguard {
    justify-content: center;
    gap: 12px;
    &--error {
      color: var(--red-800);
    }
  }
}
