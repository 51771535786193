@import '../../../styles/mixins';

.templateList {
  &__wrapper {
    padding: 24px 32px 200px;
    display: grid;
    //grid-template-columns: 1fr 2fr;
    gap: 24px;
    width: 100%;
    @include xs-up {
      grid-template: 'search' 'list' 'categories';
    }
    @include sm-up {
      grid-template: 'search search search' 'categories list list' / 270px 1fr;
    }
    @include lg-up {
      margin: 0 auto;
      max-width: 1200px;
    }

    @include phone-only {
      padding: 0 16px 200px;
    }
  }

  &__search {
    grid-area: search;

    @include phone-only {
      background-color: white;
      margin-bottom: 0;
      position: fixed !important;
      z-index: var(--high-zindex);
      padding-bottom: 16px;
    }
    &_web {
      margin-bottom: 16px;

      @include phone-only {
        margin-top: 60px;
        width: calc(100% - 38px) !important;
      }
    }

    &_telegram {
      @include phone-only {
        margin-top: 0;
        padding-top: 16px;
        width: calc(100% - 30px) !important;
      }
    }
  }

  &__selectBtn {
    @include xs-up {
      width: 40vw;
    }
    @include md-up {
      width: 310px;
    }
  }
  &__btns {
    grid-area: categories;
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex-grow: 1;
    @include md-up {
      width: 270px;
    }
    @include phone-only {
      gap: 8px;
      width: 100vw;
      position: fixed;
      left: 0;
      bottom: 0;
      z-index: 1;
      flex-direction: row;
      padding: 12px 16px 12px 16px;
      background-color: var(--gray-50);
      overflow-x: auto;
      box-shadow: 0px -6px 12px 0px rgba(12, 43, 66, 0.08);
      @include scrollbar();
    }
    @include sm-up {
      :global(.btn-secondary) {
        &:hover {
          background-color: var(--primary-100) !important;
        }
        &:active {
          color: var(--primary) !important;
        }
      }
    }
  }
  &__categoryButton {
    color: var(--gray-800) !important;
    gap: 2px;
    @include phone-only {
      &:global(.btn-secondary.btn-flat) {
        background-color: var(--grey-100);
      }
      display: flex;
      justify-content: center;
      align-items: center;
      height: 45px;
      min-width: 70px;
      overflow: hidden;
      border: none !important;
      background-color: var(--gray-100);
      span {
        line-height: 18px;
        letter-spacing: 0.02em;
      }

      &:active,
      &:focus {
        color: var(--primary, #605fe3) !important;
        box-shadow: none !important;
        border-color: transparent !important;
      }
    }

    &_active {
      color: var(--primary, #605fe3) !important;
      @include phone-only {
        background-color: transparent;
        box-shadow: none;
        outline: none;
      }
      @include sm-up {
        background-color: var(--primary-200) !important;
      }
    }
  }
  &__list {
    display: grid;
    grid-area: list;
    gap: 8px;
    margin: 0 auto;
    width: 100%;
    align-self: flex-start;

    @include xs-up {
      grid-template-columns: repeat(1, 1fr);
      max-width: 100%;
    }
    @include md-up {
      grid-template-columns: repeat(2, 1fr);
    }
    @include lg-up {
      grid-template-columns: repeat(2, 1fr);
    }
    @include xl-up {
      grid-template-columns: repeat(3, 1fr);
    }
    @include phone-only {
      padding-top: 100px;
    }
  }
  &__item {
    padding: 14px;
    border: 2px solid var(--grey-200);
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    max-width: 94vw;

    @include md-up {
      max-width: 300px;

    }

    p,
    b {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover {
      background-color: var(--primary-200);
      border-color: var(--primary-600);
      ~ .templateList__item_buttons {
        visibility: visible;
      }
    }
    &:active {
      background-color: var(--primary-300);
    }

    &__badge {
      position: absolute;
      top: 16px;
      right: 16px;
    }

    &_buttons {
      position: absolute;
      top: 6px;
      right: 6px;
      visibility: hidden;
      &:hover {
        visibility: visible;
      }
      &_button {
        background-color: white !important;
        box-shadow: none !important;
        border: none !important;
        padding: 8px !important;
        color: var(--gray-600) !important;
        &:first-child {
          border-radius: 8px 8px 0 0 !important;
        }
        &:last-child {
          border-radius: 0 0 8px 8px !important;
        }

        &_edit {
          &:hover {
            color: var(--primary-600) !important;
          }
          &:active {
            color: var(--primary-800) !important;
          }
        }
        &_delete {
          &:hover {
            color: var(--red-600) !important;
          }
          &:active {
            color: var(--red-800) !important;
          }
        }
      }
    }
  }

  &__favorite {
    &:hover {
      .templateList__item {
        background-color: var(--primary-200);
        border-color: var(--primary-600);
        ~ .templateList__item_buttons {
          visibility: visible;
        }
      }
    }
    @include phone-only {
      display: flex;
      flex-direction: row;
      gap: 8px;
      .templateList__item_buttons {
        position: relative;
        top: 0;
        right: 0;
        border: 2px solid var(--gray-200);
        background-color: var(--gray-200);
        border-radius: 8px;
        height: 100%;
        display: flex;
        visibility: visible;
        gap: 2px;
        button {
          flex-grow: 1;
        }
      }
    }
  }

  &__requestFeatureInfo {
    display: flex;
    flex-direction: column;
    gap: 8px;
    white-space: pre-line;
    padding: 12px;
    border-radius: 4px;
    background-color: var(--indigo-100);
  }
}
