@import '../../../../styles/mixins';

.ChatHistory {
  &__container {
    display: flex;
    flex-flow: column;
    flex: 1;
    min-height: 0;
  }
  &__header {
    border-top: 1px solid var(--grey-200);
    border-bottom: 1px solid var(--grey-200);
    padding: 16px 0;
  }
  &__scrollWrapper {
    flex: 1;
    overflow: auto;
  }
  &__histWrapper {
    display: flex;
    flex-direction: column;
    margin: 8px 24px 54px;
    scroll-padding-top: 8px;
    gap: 8px;

    @include phone-only {
      margin: 12px 12px 36px;
    }
  }
}
