@import '../../styles/mixins';
.sidebar {
  --header-height: 56px;
  transition: transform 0.3s ease-in-out;
  background-color: var(--gray-100);
  z-index: 50;

  display: none;
  transform: translateX(-100%);
  @include phone-only {
    position: absolute;
  }

  &__header {
    padding: 16px 16px 0 16px;
    border-bottom: none;

    @include phone-only {
      padding: 16px;
      border-bottom: 1px solid var(--gray-200);
    }
  }

  &.sideBarOpen {
    display: block;
    transform: translateX(0);
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    font-size: 14px;
    transition: all;
    background-color: var(--grey-100);
    grid-area: sidebar;

    height: calc(100vh - var(--header-height));
    height: calc(100dvh - var(--header-height));

    &:global(.dark) {
      background-color: var(--grey-950);
    }
    @include sm-up {
      position: relative;
    }
    @include phone-only {
      width: 100vw;
    }
  }
  &__btnCreate {
    justify-content: space-between;
    @include phone-only {
      padding: 0.5rem 1rem !important;
      font-size: 1rem !important;
      line-height: 1.75rem !important;
      border-radius: 0.2rem !important;
    }
  }
  &__btn {
    @include phone-only {
      height: 24px;
      width: 24px;
      color: var(--gray-800) !important;
      svg {
        height: 24px !important;
        width: 24px !important;
      }
    }

    &:global(.dark) {
      color: white;
      &:hover {
        color: var(--grey-300);
      }
    }
  }
}

.logo {
  width: 30px;
  height: 28px;

  &_isX5 {
    width: 100%;
    height: 50px;
    margin-top: -15px;
    margin-bottom: -10px;
  }

  &_isAlpha {
    width: 34px;
  }
  &_isTovie {
    width: 100%;
  }
}

.openSidebarContainer {
  border-bottom: 1px solid;
  border-bottom-color: transparent;
  padding: 16px 16px 15px 16px;
  @include phone-only {
    right: 0;
    border-bottom-color: var(--gray-200);
  }
}

.sidebarButton {
  &:hover:not(:disabled) {
    background-color: var(--gray-200);
    &:active {
      background-color: var(--gray-300);
    }
  }
  &:focus {
    box-shadow: 0 0 0 1px var(--primary);
  }
}
