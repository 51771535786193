@import 'styles/mixins';

.homePage {
  &__mainContainer {
    --header-height: 63px;
    font-size: 14px;
    background-color: var(--gray-100);
    display: grid;
    grid-template: 'content' / 1fr;
    overflow: hidden;
    height: 100vh;
    height: 100dvh;
    transition: height 0.3s ease-in-out;

    @include phone-only {
      grid-template: 'content' / 1fr;
    }

    &.isSidebarOpen {
      grid-template: 'sidebar content' / 280px 1fr;
    }

    &:global(.dark) {
      color: white;
    }
    &_offset {
      --header-height: 120px;
    }
  }
  &__navbarContainer {
    top: 0;
    @include sm-up {
      display: none;
    }
  }
  &__chatContainer {
    display: flex;
    flex-direction: column;
    grid-area: content;
    background-color: white;
    overflow: hidden;

    @include sm-up {
      padding-top: 0;
    }
  }
  &__topPanel {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 8px;
    min-height: 46px;
    &_payment {
      p {
        margin: 0 auto;
      }
      background-color: var(--yellow-100);
    }
    &_demo {
      background-color: var(--primary-100);
      justify-content: center;
      gap: 12px;
    }
    &_done {
      background-color: var(--green-200);
    }
    &_failed {
      background-color: var(--red-200);
    }
  }
}

.contentContainer {
  display: grid;
  width: 100%;
  height: 100vh;
  grid-template: 'main' 1fr 'footer' auto / 1fr;
  overflow: hidden;

  &_main {
    grid-area: main;
    max-height: 100%;
    overflow: hidden;

    &_wrapper {
      overflow-y: scroll;
      @include phone-only {
        overflow-y: auto;
        display: flex;
        flex-direction: column;
      }
    }
  }

  &__footer {
    grid-area: footer;
    padding: 16px 24px;
    a {
      text-decoration: none;
    }
  }
}

.contentContainer_withRightbar {
  display: grid;
  width: 100%;
  grid-template: 'content-header rightbar' 63px 'main rightbar' / 1fr minmax(min-content, 25vw);
  overflow: hidden;

  @include phone-only {
    height: 100%;
    grid-template: 'content-header' 'main';
  }

  &_fullWidth {
    grid-column: 1 / -1;
  }
  &_fullHeight {
    grid-row: 1 / -1;
  }
  &_isCanvasApp {
    background-color: var(--gray-100);
    grid-template: 'main rightbar-header' 63px 'main rightbar' / 1fr minmax(min-content, 360px);
    column-gap: 16px;
  }
}
