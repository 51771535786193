.radioCards {
  display: flex;
  flex-wrap: wrap;
  :global(input) {
    position: absolute;
    left: -10000px;
  }
  &_card {
    border: 2px solid var(--gray-200);
    background: var(--white);
    &_wrap {
      height: 100%;
      width: 100%;
    }

    &_active {
      border-color: var(--primary);
    }
  }
}
