.slider {
  :global(.custom-range::-webkit-slider-thumb),
  &::-webkit-slider-thumb {
    border: 1px solid var(--primary) !important;
    background-color: white !important;
    margin-top: -0.375rem !important;
  }
  :global(.custom-range::-webkit-slider-runnable-track),
  &::-webkit-slider-runnable-track {
    background-color: var(--grey-200) !important;
    height: 0.25rem !important;
  }
}
