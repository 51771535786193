.SelectAccount {
  display: flex;
  align-items: center;
  padding: 16px 16px 0 16px;

  &__button {
    position: relative;
    padding: 8px 14px !important;
    display: flex !important;
    width: 100% !important;
    justify-content: space-between !important;
    flex-wrap: nowrap !important;
    line-height: 16px !important;

    &.notification {
      &::after {
        content: '';
        position: absolute;
        top: -3px;
        right: -3px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: var(--red-600);
        border: 1px solid white;
      }
    }
  }

  &__text {
    line-height: 24px;
    color: var(--gray-950);
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
